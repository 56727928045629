import styled from "styled-components";

export const Badge = styled.div.attrs({ className: "post-card-badge" })`
  display: inline-flex;
  font-size: 12px;
  font-weight: 700;
  color: #6187fa;
  border-radius: 6px;
  text-transform: uppercase;
  margin-top: 10px;
  opacity: 0.7;
`;
