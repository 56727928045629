import styled from "styled-components";
import { Link } from "../";
import { BREAKPOINT_L } from "../../constants";

export const PostWrapper = styled(Link)`
  display: flex;
  flex-direction: column;

  &:first-child {
    grid-column: 1 / -1;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    @media (max-width: ${BREAKPOINT_L}) {
      flex-direction: column;
    }

    .gatsby-image-wrapper-constrained {
      height: 100%;
      width: 100%;
    }

    .image-wrapper {
      width: 50%;
      height: 100%;
      @media (max-width: ${BREAKPOINT_L}) {
        width: 100%;
        height: auto;
      }
    }

    .post-card-content {
      width: 50%;
      min-height: 300px;
      @media (max-width: ${BREAKPOINT_L}) {
        width: 100%;
        height: auto;
        min-height: 200px;
      }
    }

    .post-card-title {
      font-size: 36px;
      line-height: 1.2;
      margin: 10px 0 16px;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }

    .post-card-description {
      font-size: 20px;
      -webkit-line-clamp: 5;
      line-clamp: 5;
    }

    .post-card-info {
      * {
        font-size: 14px;
      }

      display: flex;
      flex: 1;
      align-items: flex-end;
    }

    .post-card-badge {
      margin-top: 0;
      font-size: 14px;
    }
  }

  .gatsby-image-wrapper-constrained {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: all 400ms;
  }

  &:hover {
    .gatsby-image-wrapper-constrained {
      transform: scale(1.05);
      filter: contrast(110%) brightness(110%);
      -webkit-filter: contrast(110%) brightness(110%);
    }

    .post-card-title {
      color: #82a2fd;
    }
  }
`;
