import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { BlogWrapper, PostCard } from "../components/Blog";
import { Layout, Link } from "../components";
import Seo from "../components/seo";
import { getCategory } from "../utils";

export const query = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!, $category: String) {
    allMarkdownRemark(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: [frontmatter___publication_date] }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            background_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            description
            publication_date(formatString: "MMM D YYYY")
            category
          }
          id
        }
      }
    }
  }
`;

const PaginationWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 48px;

  a {
    font-size: 20px;
  }
`;

const LinkWrapper = styled.div``;

function Pagination({ currentPage, numPages, category }) {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = () => {
    const prev = currentPage - 1 === 1 ? "/" : `/${currentPage - 1}`;
    return category ? `/${category}${prev}` : prev;
  };
  const nextPage = () => {
    return category ? `/${category}/${currentPage + 1}` : `/${currentPage + 1}`;
  };

  return (
    <PaginationWrapper>
      <LinkWrapper>
        {!isFirst && (
          <Link to={prevPage()} rel="prev">
            ← Previous Page
          </Link>
        )}
      </LinkWrapper>
      {/*<PageNumbersWrapper>*/}
      {/*  {Array.from({ length: numPages }, (_, i) => (*/}
      {/*    <Link*/}
      {/*      key={`pagination-number${i + 1}`}*/}
      {/*      to={`/${i === 0 ? "" : i + 1}`}*/}
      {/*    >*/}
      {/*      {i + 1}*/}
      {/*    </Link>*/}
      {/*  ))}*/}
      {/*</PageNumbersWrapper>*/}
      <LinkWrapper>
        {!isLast && (
          <Link to={nextPage()} rel="next">
            Next Page →
          </Link>
        )}
      </LinkWrapper>
    </PaginationWrapper>
  );
}

const BlogPage = ({ data, pageContext }) => {
  const posts = data?.allMarkdownRemark?.edges || [];
  const { category } = pageContext;

  const currentCategory = getCategory(category);

  return (
    <Layout title={currentCategory} withTitle>
      <BlogWrapper>
        {posts.map(post => (
          <PostCard key={post.node.id} {...post} />
        ))}
      </BlogWrapper>
      <Pagination
        currentPage={pageContext.humanPageNumber}
        numPages={pageContext.numberOfPages}
        category={pageContext.category}
      />
    </Layout>
  );
};

export default BlogPage;

export const Head = () => <Seo title="Drope.me Newsroom" />;
