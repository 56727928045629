import styled from "styled-components";

export const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 24px;
  row-gap: 52px;

  &:first-child {
    grid-column: 1 / -1;
  }

  .gatsby-image-wrapper-constrained {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
`;
